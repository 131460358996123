import { NextPageContext } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import CheckoutContentSquareTag from '../components/CheckoutContentSquareTag';
import withHeaderFooter,
{
  loadFooterData,
  loadHeaderData,
} from '../components/WithHeaderFooter';
import styles from '../styles/Home.module.css';
import { clientMonoRedirect } from '../utils/public/ClientUtils';
import fetch from '../utils/public/fetch';
import pagePaths from '../utils/public/pagePaths';
import { GlobalStyles } from '@minted/minted-components';

const OktaSignInWidget = dynamic(
  () => import('../components/LoginComponent'),
  {
    ssr: false,
  }
);

const App = ({
  markup,
}: { markup?: any }) => {
  useEffect(() => {
    // Make API call to check login status and refresh
    (async () => {
      const res = await fetch('/api/check-login-status-and-refresh', {
        method: 'POST',
      });

      if (res.ok) {
        const json = await res.json();

        // The user is already logged in so redirect them back to mono
        if (json.data?.sub) {
          clientMonoRedirect();
        }
      }
    })();

    return () => {
    };
  }, []);
  const router = useRouter();
  const {
    destination,
  } = router.query;

  return (
    <>
      <GlobalStyles />
      {destination === '/checkout' && <CheckoutContentSquareTag />}
      <div className={styles.container}>
        <OktaSignInWidget
          onSuccessfulLogin={clientMonoRedirect}
          paths={pagePaths}
        />
      </div>
    </>
  );
};

// getServerSideProps must be set at the page level.  Hence all these work-arounds.
// the getServerSideProps loads the header from app-renderer and puts it in the props.
// the withHeaderFooter wraps this component and puts it between header/footer.
export const getServerSideProps = async (context: NextPageContext) => {
  const headerProps = await loadHeaderData()(context);
  const footerProps = await loadFooterData()(context);

  return {
    props: {
      ...headerProps.props,
      ...footerProps.props,
    },
  };
};
export default withHeaderFooter(App);
